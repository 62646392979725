<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create New Packeage
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="eventPackageCreateForm"
        #default="{invalid}"
      >
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Package Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Name*"
                label-for="h-event-package-name"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-event-package-name"
                    v-model="name"
                    placeholder="Name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    @input="nameValidation == true ? nameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Available Quantity*"
                label-for="h-event-package-availableQuantity"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Available Quantity"
                  vid="availableQuantity"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="h-event-package-availableQuantity"
                    v-model="availableQuantity"
                    placeholder="Available Quantity"
                    :state="(errors.length > 0 || availableQuantityValidation) ? false : null"
                    name="availableQuantity"
                    @input="availableQuantityValidation == true ? availableQuantityValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="availableQuantityValidation"
                    class="text-danger"
                  >
                    {{ availableQuantityError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Pricing (S$)**"
                label-for="h-event-package-price"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Pricing (S$)*"
                  vid="price"
                  rules="required"
                >
                  <b-form-input
                    id="h-event-package-price"
                    v-model="price"
                    placeholder="Pricing (S$)*"
                    :state="(errors.length > 0 || priceValidation) ? false : null"
                    name="price"
                    @input="priceValidation == true ? priceValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="priceValidation"
                    class="text-danger"
                  >
                    {{ priceError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Allow Waiting List*"
                label-for="h-event-waitingList"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Allow Waiting List"
                  vid="waitingList"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-event-waitingList"
                    v-model="waitingList"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                  >
                    <b-form-radio
                      v-for="(option, index) in waitingListOptions"
                      :key="index"
                      :value="option.value"
                    >
                      <feather-icon
                        v-if="waitingList === option.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="waitingListValidation"
                    class="text-danger"
                  >
                    {{ waitingListInvalidMessage }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Custom Form
            </h3>
          </template>
          <b-row
            v-for="(field, index) in customFields"
            :key="index"
            ref="row"
          >
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Component Type"
                vid="prospectBookable"
                rules="required"
              >
                <b-form-group
                  label="Component Type*"
                  :label-for="'h-event-package-input-type' + index"
                  label-cols-md="4"
                  :state="(errors.length > 0 || field.inputTypeInvalid) ? false : null"
                >
                  <v-select
                    :id="'h-event-package-input-type' + index"
                    v-model="field.type"
                    label="title"
                    :options="inputTypeOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="updateExtraFileds(index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="field.inputTypeInvalid"
                    class="text-danger"
                  >
                    {{ field.inputTypeInvalidMessage }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />
            <b-col
              v-if="field.type == 'file'"
              cols="md-8"
            >
              <b-form-group
                label="Upload Image*"
                :label-for="'h-event-package-file' + index"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Image"
                  vid="h-event-package-file"
                  rules="required"
                >
                  <b-form-file
                    :id="'h-event-package-file' + index"
                    v-model="field.file"
                    accept="image/*"
                    @input="setImage(index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="field.fileValidation"
                    class="text-danger"
                  >
                    {{ field.fileValidationMessage }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="field.type == 'file'"
              cols="md-4"
            />

            <b-col cols="12">
              <div
                v-if="field.type == 'title-and-text'"
                class="row"
              >
                <b-col cols="md-6">
                  <b-form-group
                    label="Title*"
                    :label-for="'h-event-package-label' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      vid="label"
                      rules="required"
                    >
                      <b-form-input
                        :id="'h-event-package-label' + index"
                        v-model="field.label"
                        placeholder="Title"
                        :state="(errors.length > 0 || field.labelValidation) ? false : null"
                        name="label"
                        @input="field.labelValidation == true ? field.labelValidation = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.labelValidation"
                        class="text-danger"
                      >
                        {{ field.labelValidationMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />

                <b-col cols="md-8">
                  <b-form-group
                    label="Text"
                    :label-for="'h-event-package-description' + index"
                    label-cols-md="3"
                  >
                    <b-form-textarea
                      :id="'h-event-package-description' + index"
                      v-model="field.description"
                      placeholder="Text"
                      rows="3"
                      name="description"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="md-4" />
              </div>
            </b-col>

            <b-col cols="12">
              <div
                v-if="field.type == 'short-answer' || field.type == 'long-answer' || field.type == 'single-select' || field.type == 'multi-select'"
                class="row"
              >
                <b-col cols="md-6">
                  <b-form-group
                    label="Label*"
                    :label-for="'h-event-package-label' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <b-form-input
                        :id="'h-event-package-label' + index"
                        v-model="field.label"
                        placeholder="Field Label"
                        :state="(errors.length > 0 || field.labelValidation) ? false : null"
                        name="label"
                        @input="field.labelValidation == true ? field.labelValidation = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.labelValidation"
                        class="text-danger"
                      >
                        {{ field.labelValidationMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />
                <b-col cols="md-8">
                  <b-form-group
                    label="Description"
                    :label-for="'h-event-package-description' + index"
                    label-cols-md="3"
                  >
                    <b-form-textarea
                      :id="'h-event-package-description' + index"
                      v-model="field.description"
                      placeholder="Description"
                      rows="3"
                      name="description"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="md-4" />

                <b-col
                  v-if="field.type == 'multi-select'"
                  cols="md-6"
                >
                  <b-form-group
                    label="Add Selection Option*"
                    :label-for="'h-event-package-extraOptions' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      v-for="(opt, key) in field.extraOptions"
                      :key="key"
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <div class="inlineElement mb-2">
                        <b-form-input
                          :id="'h-event-package-label' + index + 'extraOptionsKey' + key"
                          v-model="opt.key"
                          placeholder="Type an option Name"
                          :state="(errors.length > 0 || opt.keyValidation) ? false : null"
                          name="key"
                          class="mr-2"
                          @input="opt.keyValidation == true ? opt.keyValidation = false : null"
                        />
                        <b-form-input
                          :id="'h-event-package-label' + index + 'extraOptions' + key"
                          v-model="opt.value"
                          placeholder="Add a text to display in Calendar Title"
                          :state="(errors.length > 0 || opt.valueValidation) ? false : null"
                          name="value"
                          @input="opt.valueValidation == true ? opt.valueValidation = false : null"
                        />
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-secondary"
                          type="button"
                          class="btn-icon rounded-circle"
                          @click="removeCustomFieldOptions(index, key)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            size="18"
                          />
                        </b-button>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="opt.valueValidation"
                        class="text-danger"
                      >
                        {{ opt.valueValidationMessage }}
                      </small>
                    </validation-provider>
                    <p
                      class="addOptionText"
                      @click="addCustomFieldOptions(index)"
                    >
                      Add an Option
                    </p>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="field.type == 'multi-select'"
                  cols="md-6"
                />

                <b-col cols="md-6">
                  <b-form-group
                    label="Display in Calendar Title*"
                    :label-for="'h-event-package-displayInCalendar' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Display in Calendar Title"
                      vid="displayInCalendar"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-event-package-displayInCalendar' + index"
                        v-model="field.displayInCalendar"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.displayInCalendar === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.displayInCalendarInvalid"
                        class="text-danger"
                      >
                        {{ field.displayInCalendarInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />

                <b-col
                  v-if="field.type == 'multi-select'"
                  cols="md-6"
                >
                  <b-form-group
                    label="User Can Select*"
                    :label-for="'h-event-package-multiSelectType' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="User Can Select"
                      vid="multiSelectType"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-event-package-multiSelectType' + index"
                        v-model="field.multiSelectType"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in multiSelectOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.multiSelectType === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.multiSelectTypeInvalid"
                        class="text-danger"
                      >
                        {{ field.multiSelectTypeInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="field.type == 'multi-select'"
                  cols="md-6"
                />

                <b-col cols="md-6">
                  <b-form-group
                    label="Required?*"
                    :label-for="'h-event-package-required' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Required"
                      vid="required"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-event-package-required' + index"
                        v-model="field.required"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.required === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.requiredInvalid"
                        class="text-danger"
                      >
                        {{ field.requiredInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />
              </div>
            </b-col>

            <b-col cols="12">
              <p
                class="removeText"
                @click="removeCustomField(index)"
              >
                Remove this component
              </p>

              <hr class="dividerHR">
            </b-col>
          </b-row>

          <b-button
            v-if="customFields.length > 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            @click="addCustomField"
          >
            <span class="align-middle">Add Custom Field</span>
          </b-button>
          <b-row v-else>
            <b-col>
              <div class="empty_block">
                <h4>No item yet</h4>
                <b-button
                  type="button"
                  variant="flat-primary"
                  @click="addCustomField"
                >
                  <span class="align-middle">Add Custom Field</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'appointment-events-show', params: { id: $route.params.parent } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Package</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormFile, BFormTextarea, BFormRadioGroup, BForm, BButton, BNav, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BFormRadioGroup,
    BForm,
    BButton,
    BNav,
    BFormRadio,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      availableQuantity: '',
      price: '',
      waitingList: 'yes',
      nameError: 'Valid name is required',
      nameValidation: false,
      availableQuantityError: 'Valid quantity is required',
      availableQuantityValidation: false,
      priceError: 'Valid price is required',
      priceValidation: false,
      waitingListError: 'Valid value is required',
      waitingListValidation: false,
      inputTypeOptions: [
        { title: 'Image', code: 'file' },
        { title: 'Title and Text', code: 'title-and-text' },
        { title: 'Short Answer Field', code: 'short-answer' },
        { title: 'Long Answer Field', code: 'long-answer' },
        { title: 'Selection List Field (Add-Ons)', code: 'multi-select' },
        { title: 'Single Checkbox Field', code: 'single-select' },
      ],
      customFields: [],
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      multiSelectOptions: [
        { text: 'One Option Only', value: 'single' },
        { text: 'Multiple Options', value: 'multiple' },
      ],
      statusOptions: [
        { title: 'Published', code: 'published' },
        { title: 'Unpublished', code: 'unpublished' },
        { title: 'Draft', code: 'draft' },
      ],
      waitingListOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],

      // validation rules
      required,
    }
  },
  methods: {
    addCustomField() {
      this.customFields.push({
        type: 'title-and-text',
        label: '',
        description: '',
        required: 'yes',
        displayInCalendar: 'yes',
        extraOptions: [],
        file: null,
        base64File: null,
        multiSelectType: 'single',
        typeInvalid: false,
        typeInvalidMessage: '',
        fileInvalid: false,
        fileInvalidMessage: '',
        labelInvalid: false,
        labelInvalidMessage: '',
        requiredInvalid: false,
        requiredInvalidMessage: '',
        displayInCalendarInvalid: false,
        displayInCalendarInvalidMessage: '',
        multiSelectTypeInvalid: false,
        multiSelectTypeInvalidMessage: '',
      })
    },
    addCustomFieldOptions(index) {
      this.customFields[index].extraOptions.push({
        key: '',
        keyInvalid: false,
        keyInvalidMessage: '',
        value: '',
        valueInvalid: false,
        valueInvalidMessage: '',
      })
    },
    updateExtraFileds(index) {
      // eslint-disable-next-line no-unused-expressions
      this.customFields[index].inputTypeInvalid === true ? this.customFields[index].inputTypeInvalid = false : null
      if (this.customFields[index].type === 'multi-select') {
        this.customFields[index].extraOptions.push({
          key: '',
          keyInvalid: false,
          keyInvalidMessage: '',
          value: '',
          valueInvalid: false,
          valueInvalidMessage: '',
        })
      } else {
        this.customFields[index].extraOptions = []
      }
    },
    removeCustomFieldOptions(index, key) {
      if (this.customFields[index].extraOptions.length > 1) {
        this.customFields[index].extraOptions.splice(key, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one value.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeCustomField(index) {
      this.customFields.splice(index, 1)
    },

    setImage(index) {
      const reader = new FileReader()
      reader.readAsDataURL(this.customFields[index].file)
      reader.onload = () => {
        this.customFields[index].base64File = reader.result
      }
    },
    submitForm() {
      this.$refs.eventPackageCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('event', this.$route.params.parent)
          formData.append('name', this.name)
          formData.append('price', this.price)
          formData.append('availableQuantity', this.availableQuantity)
          formData.append('waitingList', this.waitingList)
          const trimemdData = this.customFields.map(field => {
            const extraOptionsData = field.extraOptions.map(option => {
              const optionData = {
                key: option.key,
                value: option.value,
              }
              return optionData
            })

            const properties = {
              type: field.type,
              file: field.base64File,
              label: field.label,
              description: field.description,
              required: field.required,
              displayInCalendar: field.displayInCalendar,
              multiSelectType: field.multiSelectType,
              extraOptions: extraOptionsData,
            }
            return properties
          })

          formData.append('customFields', JSON.stringify(trimemdData))

          this.$http.post('appointment/event-packages/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Event Package Created',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: true,
                  cancelButtonText: 'View Created Entry',
                  allowOutsideClick: false,
                  confirmButtonText: 'Back to Event',
                  customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'appointment-events-show', params: { id: this.$route.params.parent } })
                    } else {
                      this.$router.push({ name: 'appointment-event-packages-show', params: { parent: this.$route.params.parent, id: response.data.data._id } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'price') {
                    this.priceError = validationError.msg
                    this.priceValidation = true
                  } else if (validationError.param === 'availableQuantity') {
                    this.availableQuantityError = validationError.msg
                    this.availableQuantityValidation = true
                  } else if (validationError.param === 'waitingList') {
                    this.waitingListError = validationError.msg
                    this.waitingListValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
</style>
